<!--家校训练服务-上课-结束上课-->
<template>
  <div class="training-completed">
    <div class="head" v-if="[2].includes(current)">
      <div>
        <div style="font-size: 16px; font-weight: 600; color: #333333" v-if="[2].includes(current)">编辑阶段素材</div>
      </div>
      <div class="head-right">
        <div class="teacher">本次上课老师：{{ userInfo.username }}</div>
        <a-button style="margin-left: 24px" v-if="[2].includes(current)" @click="back()"
          ><a-icon type="left" />返回</a-button
        >
      </div>
    </div>

    <!--结束课程-->
    <template v-if="current === 1">
      <a-row class="complete">
        <a-col :span="12" :offset="6" style="text-align: center">
          <div><img src="~@/assets/icons/answer-right.png" /> 训练完成，请为本次课程撰写课堂评语</div>
        </a-col>
        <a-col :span="6" style="text-align: right">
          <a-button type="primary" @click="handleSaveComments">保存记录</a-button>
        </a-col>
      </a-row>
      <a-row class="complete" style="color: red; font-size: 16px; padding-top: 0">
        <a-col :span="12" :offset="6" style="text-align: center"> 评语可在上课记录中修改 </a-col>
      </a-row>
      <end-of-crouse
        ref="endOfCrouse"
        @initData="initData"
        :isHideBtn="false"
        :isEdit="false"
        v-if="current === 1"
        :is-class-record="false"
        @change="changeCurrent"
      ></end-of-crouse>
    </template>

    <!--编辑素材-->
    <template v-if="current === 2">
      <div style="background: #fff; padding: 0 24px">
        <div style="margin-bottom: 16px">
          <span style="font-size: 16px; font-weight: 600; color: #333333; margin-right: 32px">{{
            phaseDetail && phaseDetail.skillName
          }}</span>
          <span>
            第
            {{ phaseDetail && phaseDetail.phaseNumber && convertToChineseNumber(phaseDetail.phaseNumber) }}阶段：
            {{ phaseDetail.name }}
          </span>
        </div>

        <div style="margin-bottom: 24px">
          <span style="font-size: 14px; font-weight: 600; color: #333333">素材说明：</span>
          {{ phaseDetail && phaseDetail.materialDescription }}
        </div>
        <!--编辑素材-->
        <basis-form
          style="margin-bottom: 40px"
          ref="editMaterial"
          type="plan"
          :mainFieldId="NaN"
          :fieldId="NaN"
          origin="trainingCompleted"
          :only-material="true"
        />
        <div style="width: 320px; height: 44px">
          <a-button :loading="loading" type="primary" block shape="round" @click="onSubmit">保存</a-button>
        </div>
      </div>
    </template>
    <!--记录详情-->
    <div v-if="current === 3">
      <class-record-detail ref="classRecordDetail" @back="back()"></class-record-detail>
    </div>
  </div>
</template>
<script>
import * as Api from '@/api/serviceManager'
import * as planApi from '@/api/plan'
import endOfCrouse from '../components/end-of-crouse'
import { convertToChineseNumber } from '@/utils/util'
import classRecordDetail from '@/views/home-school-service/detail/class-record/class-record-detail'
import BasisForm from '@/views/skill-management/skill/phase/components/BasisForm'
import { mapGetters, mapActions } from 'vuex'

import { debounce } from 'lodash'
export default {
  components: {
    endOfCrouse,
    BasisForm,
    classRecordDetail
  },
  data() {
    return {
      data: {
        loading: false,
        ready: false,
        current: Number, // 1结束课程  2编辑素材 3记录详情（目前只有1）
        fieldsOptions: [],
        phaseDetail: {} // current 为2  编辑素材
      }
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'getUserInfo'
    })
  },
  created() {
    this.current = 1
    this.initData({})
  },
  methods: {
    ...mapActions(['changeSpinning']),

    handleSaveComments() {
      const { teachingClassId: id, descript: comments } = this.$refs.endOfCrouse
      if (!comments) {
        this.$notification['error']({ message: '请填写课堂评语' })
        // eslint-disable-next-line
        return
      }
      const params = { comments, id }
      Api.finishComments(params).then(res => {
        const { code } = res
        code === 0 && this.homeSchoolDetail()
      })
    },
    onSubmit: debounce(function() {
      this.submit()
    }, 3000),
    // 保存素材
    submit() {
      const {
        phaseDetail: { skillId, id },
        $notification,
        $refs: { editMaterial }
      } = this
      editMaterial.validate().then(res => {
        const valid = res.every(item => item === true)
        if (valid) {
          this.loading = true
          try {
            const { planformatForm } = editMaterial
            const form = planformatForm()
            console.log(form)
            form.skillId = skillId
            form.id = id

            planApi
              .updatePhase(form)
              .then(res => {
                $notification['success']({ message: '操作成功' })
                this.back()
              })
              .finally(() => (this.loading = false))
          } catch (error) {
            console.log(error)
            $notification['error']({ message: '提示', description: '脚本错误' })
            this.loading = false
          }
        }
      })
    },
    // 初始化数据
    initData() {
      const {
        query: { teachingClassId, studentId }
      } = this.$route
      // 报告详情
      if (this.current === 1) {
        this.changeSpinning(true)
        const params = { teachingId: teachingClassId }
        Api.getTeachingReport(params)
          .then(res => {
            this.changeSpinning(false)
            const { code, data } = res
            if (code === 0) {
              this.$refs.endOfCrouse.initData({ data, teachingClassId })
            }
          })
          .catch(() => {
            this.changeSpinning(false)
          })
      }
      // 编辑素材
      if (this.current === 2) {
        planApi.phaseDetail(this.phaseDetail.id).then(res => {
          this.$refs.editMaterial.planinitData(res.data, studentId)
          this.$forceUpdate()
        })
      }
      // 记录详情
      if (this.current === 3) {
        const params = { teachingId: teachingClassId }
        Api.classRecordDetail(params).then(res => {
          const { code, data } = res
          if (code === 0) {
            const { recordDetailsParentFieldList, teacherName } = data
            recordDetailsParentFieldList &&
              recordDetailsParentFieldList.map((item, index) => {
                const { recordDetailsFieldList } = item
                recordDetailsFieldList &&
                  recordDetailsFieldList.map((item1, index1) => {
                    const { recordDetailsSkillList } = item1
                    recordDetailsSkillList &&
                      recordDetailsSkillList.map((item2, index2) => {
                        const { recordDetailsPhase } = item2
                        const { planPhaseId } = recordDetailsPhase
                        if (this.phaseDetail.id === planPhaseId) {
                          console.log(item2)
                          this.$refs.classRecordDetail.initData({ ...item2, ...{ teacherName } })
                        }
                      })
                  })
              })
          }
        })
      }
    },
    //  学员详情
    homeSchoolDetail() {
      const {
        query: { studentId }
      } = this.$route
      Api.homeSchoolDetail({ studentId }).then(res => {
        const { code, data } = res
        if (code === 0) {
          this.$router.push({
            name: 'homeSchoolDetail',
            params: { id: studentId },
            query: {
              row: JSON.stringify(data) // 将对象转成json字符串
            }
          })
        }
      })
    },
    changeCurrent({ item, current }) {
      this.phaseDetail = item
      this.current = current
      this.initData()
      // console.log(this.current)
      this.$forceUpdate()
    },
    back() {
      // this.phaseDetail = {}
      this.changeCurrent({ current: 1 })
      // this.$forceUpdate()
    },
    convertToChineseNumber
  },
  watch: {
    current(val) {
      if (val) {
        console.log('watch')
        // this.initData({})
      }
    }
  }
}
</script>
<style lang="less" scoped>
.aside-wrapper {
  height: calc(100vh - 48px);
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    overflow-y: auto;
  }
}

/deep/ .ant-card-head {
  border-bottom-width: 1px !important;
  background: #f7f8fc;
  border-radius: 10px 10px 0 0;
  .ant-card-head-title {
    font-size: 14px;
    font-weight: 600;
    color: #1a1a1a;
    line-height: 28px;
  }
}

.class-record {
  /deep/ .ant-card-head {
    background: #3a5ff8;
    .ant-card-head-title {
      color: #fff;
    }
  }
}
p {
  margin-bottom: 0;
}

.training-completed {
  background: #f7f8fc;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 24px;
    background: #fff;
    .head-right {
      display: flex;
      align-items: center;
      .teacher {
        font-size: 14px;
        font-weight: 600;
        color: #1a1a1a;
      }
    }
  }

  .complete {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 24px 32px 0 32px;
    background: #fff;
    font-size: 18px;
    font-weight: 600;
    color: #1a1a1a;
    line-height: 32px;
    img {
      width: 32px;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
}
</style>
